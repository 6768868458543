<template>
  <ion-page>
    <ion-content>
      <div class="main">
        <div class="topBackground">
          <ion-text class="regular cWhite ion-text-center">
            <h1 class="ion-no-margin menuTitleFont">Mes Missions</h1>
          </ion-text>
        </div>
        <div class="maxWidth">
          <div v-if="address">
            <div>
              <ion-button v-on:click="seeSoftwareFilter()" class="ion-margin">
                Filtre Logiciel({{ softwaresSelected.length }}/{{ softwares.length }})
              </ion-button>

              <ion-button v-on:click="seeDepartmentFilter()" class="ion-margin">
                Filtre Département({{ departmentsSelected.length || 0 }}/{{ departments.length || 0 }})
              </ion-button>

              <ion-button v-on:click="toggleDistinctSignsSensibility()" class="ion-margin">
                Typologie pharma {{ cdistinctSignsSensibilitySelected }}
              </ion-button>

              <div v-if="isSeeSoftwareFilter">
                <ion-chip @click="clickChipSoftware(software.name)" :color="software.color"
                          v-for="software in softwares" :key="software.id">
                  <ion-label>{{ software.name }}</ion-label>
                </ion-chip>
              </div>

              <div v-if="isSeeDepartmentFilter">
                <ion-chip @click="clickChipDepartment(department.name)" :color="department.color"
                          v-for="department in departments" :key="department">
                  <ion-label>{{ department.name }}</ion-label>
                </ion-chip>
              </div>

              <div v-if="missionsDisplayed.length > 0">
                <div v-for="mission in missionsDisplayed" :key="mission.id">
                  <Mission
                      :distance="mission.distance"
                      :description="mission.description"
                      :address="mission.displayAddress"
                      :pharmacyName="mission.pharmacyName"
                      :software="mission.software"
                      :title="mission.title"
                      :vipType="mission.vipType"
                      :dateStart="mission.dateStart"
                      :dateEnd="mission.dateEnd"
                      :uniqueUser="mission.uniqueUser"
                      :slots-size="mission.slotsSize"
                      :slots="mission.slotsMonths"
                      :triggerFalseSeeSlots="triggerFalseSeeSlots"
                      :is-premium="mission.isPremium"
                      :id="mission.id"
                      :distinct-signs-sensibility="mission.distinctSignsSensibility"
                  >
                  </Mission>
                </div>
                <ion-button @click="takeMoreMissions" v-if="shouldTakeMoreMission" class="ion-margin">Oui !! Je veux
                  plus de missions
                </ion-button>
              </div>
              <div v-else-if="!hasLoad">
                <ion-text color="primary">
                  <h3>
                    Les missions arrivent 🤩🤩🤩
                  </h3>
                </ion-text>
              </div>
              <div v-else>
                <ion-text color="primary">
                  <h3>Il n'y a pas de missions autour de toi 😢</h3>
                </ion-text>
              </div>
            </div>
          </div>
          <div v-else>
            <ion-text color="primary">
              <h3>Où es-tu ? 😊</h3>
              <AddressField @refresh="refresh"></AddressField>
            </ion-text>
          </div>
        </div>
      </div>
      <div v-if="!isDesktop" class="footerBackground">
        <img
            class="ion-margin-bottom logoFooter"
            src="../../public/assets/icon/logo-alloo-pharma.svg"
        />
      </div>
      <div v-else class="footerNotMobile">
        <img
            class="ion-margin-bottom logoFooter"
            src="../../public/assets/icon/alloopharma-logo.png"
        />
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import store, {POSSIBLE_DISTINCT_SIGNS_ROTATION, POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES} from "@/store";
import moment from "moment";
import AddressField from "@/components/AddressField.vue";
import {IonButton, IonChip, IonContent, IonLabel, IonPage, IonText} from "@ionic/vue";
import Mission from "@/components/Mission.vue";
import {getMissions, getSoftwares} from "@/services/api";

export default {
  name: "Missions",
  components: {
    AddressField,
    Mission,
    IonText,
    IonContent,
    IonPage,
    IonButton,
    IonChip,
    IonLabel
  },
  data() {
    return {
      colorCanTakeIfSuggest: "dark",
      hasLoad: false,
      shouldTakeMoreMission: true,
      triggerFalseSeeSlots: 0,
      missionsPotential: [],
      missionsDisplayed: [],
      softwaresSelected: [],
      departmentsSelected: [],
      softwares: [],
      departments: [],
      distinctSigns: [],
      isSeeSoftwareFilter: false,
      isSeeDepartmentFilter: false,
      isDistinctSignsFilter: false,
    };
  },

  ionViewDidEnter() {
    this.triggerFalseSeeSlots++
  },
  computed: {
    isDesktop() {
      return store.getters.isDesktop;
    },
    address() {
      return store.getters.address;
    },
    cDepartments() {
      const departments = []
      const isInitDeparmentSelectedEmpty = store.getters.departmentsSelected.length === 0
      store.getters.departments.forEach(department => {
        const objDepartment = {
          name: department,
        }
        if (this.departmentsSelected.includes(department) || isInitDeparmentSelectedEmpty) {
          objDepartment.color = "primary"
        } else {
          objDepartment.color = "medium"
        }
        departments.push(objDepartment)
        if (isInitDeparmentSelectedEmpty && !this.departmentsSelected.includes(department))
          this.departmentsSelected.push(department)
      });
      return departments
    },
    cSoftwares() {
      const softwares = []
      const isInitSoftwareSelectedEmpty = store.getters.softwaresSelected.length === 0
      store.getters.softwares.forEach(software => {
        const objSoftware = {
          name: software,
        }
        if (this.softwaresSelected.includes(software) || isInitSoftwareSelectedEmpty) {
          objSoftware.color = "primary"
        } else {
          objSoftware.color = "medium"
        }
        softwares.push(objSoftware)
        if (isInitSoftwareSelectedEmpty)
          this.softwaresSelected.push(software)
      });
      return softwares
    },
    distinctSignsSensibilitySelected() {
      return store.getters.distinctSignsSensibilitySelected
    },
    cdistinctSignsSensibilitySelected() {
      return POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES[this.distinctSignsSensibilitySelected]
    },
  },
  ionViewWillEnter() {
    store.dispatch("resetMissionPotential", []);
    getMissions(0).then(() => {
      this.hasLoad = true;
      this.missionsPotential = this.getMissionsPotential();
      this.missionsDisplayed = this.getMissionsPotentialFiltered();
      this.softwaresSelected = store.getters.softwaresSelected;
      this.departmentsSelected = store.getters.departmentsSelected
      this.distinctSignsSensibilitySelected = store.getters.distinctSignsSensibilitySelected
      this.softwares = this.cSoftwares;
      this.departments = this.cDepartments;
    });
    getSoftwares()
  },
  ionViewWillLeave() {
    store.dispatch("resetMissionPotential", []);
  },
  methods: {
    refresh() {
      document.location.reload();
    },
    clickChipSoftware(softwareSelected) {
      this.softwares.forEach(software => {
        if (software.name === softwareSelected) {
          if (software.color === "medium") {
            software.color = "primary"
            this.softwaresSelected.push(software.name)
          } else {
            software.color = "medium"
            const index = this.softwaresSelected.indexOf(software.name);
            if (index > -1) {
              this.softwaresSelected.splice(index, 1);
            }
          }
          store.dispatch("setPreferencesFilter", {softwares: this.softwaresSelected})
          this.reloadMissions()
        }
      });
    },

    clickChipDepartment(departmentSelected) {
      this.departments.forEach(department => {
        if (department.name === departmentSelected) {
          if (department.color === "medium") {
            department.color = "primary"
            this.departmentsSelected.push(department.name)
          } else {
            department.color = "medium"
            const index = this.departmentsSelected.indexOf(department.name);
            if (index > -1) {
              this.departmentsSelected.splice(index, 1);
            }
          }
        }
      });
      store.dispatch("setPreferencesFilter", { departments: this.departmentsSelected })
      this.reloadMissions();
    },

    toggleDistinctSignsSensibility() {
      const distinctSignsSensibility = POSSIBLE_DISTINCT_SIGNS_ROTATION[this.distinctSignsSensibilitySelected]

      store.dispatch("setPreferencesFilter", { distinctSignsSensibility })
      this.reloadMissions();
    },


    seeSoftwareFilter() {
      this.isSeeSoftwareFilter = !this.isSeeSoftwareFilter
      this.isSeeDepartmentFilter = false;
    },
    seeDepartmentFilter() {
      this.isSeeDepartmentFilter = !this.isSeeDepartmentFilter
      this.isSeeSoftwareFilter = false;
    },
    getMissionsPotentialFiltered() {
      const missionsFiltered = []
      if (this.softwaresSelected.length === 0) {
        return this.missionsPotential;
      }
      this.missionsPotential.forEach(mission => {
        if (this.softwaresSelected.includes(mission.software))
          missionsFiltered.push(mission)
      });
      return missionsFiltered
    },

    takeMoreMissions() {
      this.hasLoad = false;
      this.reloadMissions(this.missionsPotential.length);
    },
    reloadMissions(offset = 0) {
      this.missionsDisplayed = this.getMissionsPotentialFiltered()
      this.hasLoad = false;
      getMissions(offset).then((missions) => {
        if (missions.length !== 25) {
          this.shouldTakeMoreMission = false;
        }
        this.missionsPotential = this.getMissionsPotential();
        this.missionsDisplayed = this.getMissionsPotentialFiltered()
        this.hasLoad = true;
        this.departments = this.cDepartments;
      });
    },
    getMissionsPotential() {
      const missions = store.getters.missionsPotential.sort((a, b) => a.distance - b.distance);
      if (missions.length === 0) {
        return [];
      }
      missions
          .filter(mission => !mission.isComputed)
          .forEach(mission => {
            if (mission.displayAddress[1] === "-") {
              mission.displayAddress = mission.displayAddress.substring(2);
            }
            mission.isComputed = true;
            mission.dateStart = moment(mission.dateStart, "YYYY-MM-DD")
                .locale("fr")
                .format("D MMM");
            mission.dateEnd = moment(mission.dateEnd, "YYYY-MM-DD")
                .locale("fr")
                .format("D MMM YYYY");
            mission.slotsMonths.forEach(month => {
              const yearMonth = month.dateYearMonth;
              month.dateYearMonth = moment(month.dateYearMonth, "YYYY-MM")
                  .locale("fr")
                  .format("MMMM YYYY");
              month.dateYearMonth =
                  month.dateYearMonth.charAt(0).toUpperCase() +
                  month.dateYearMonth.slice(1);
              month.days.forEach(day => {
                if (day.dayNum.length === 1) {
                  day.dayNum = `0${day.dayNum}`;
                }
                day.dayName = day.dayName.substring(0, 3);
                day.dayName += ".";
                day.slotsDay.forEach(slot => {
                  slot.date = `${yearMonth}-${day.dayNum}`;
                  if (slot.canTakeIfSuggest) {
                    slot.color = this.colorCanTakeIfSuggest;
                  }
                });
              });
            });
          });
      return missions;
    }
  }
};
</script>
<style scoped>
h1 {
  padding: 50px;
  margin-top: -20px;
}

</style>
