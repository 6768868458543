<template>
  <div id="container">
    <ion-card class="fullCard">
      <ion-card-header>
        <ion-card-title>
          <div v-if="dateTimeMissionConfirmed" class="ion-margin-bottom black">
            <h2 class="dateConfirmed">{{ dateTimeMissionConfirmed.date }}</h2>
            <h3 class="ion-text-left ">
              🕒 {{ dateTimeMissionConfirmed.timeTable }}
            </h3>
            <h3 class="ion-text-left" v-if="rest">☕ {{ rest }}h</h3>
          </div>
          <h1 :class="['headerCard', isPremium && 'headerCard__gold']">
            <span class="address">
              {{ address || "Adresse non renseignée" }}
              {{ POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES[distinctSignsSensibility] }}
            </span>
          </h1>
          <div v-if="!dateTimeMissionConfirmed">
            <h2 class="black">
              <span class="dateMissionPossible">
                {{ formatedDate }}
              </span>
            </h2>
          </div>
        </ion-card-title>
      </ion-card-header>
      <div v-if="lat && lng">
        <Map :lng="lng" :lat="lat" :markers="getMarkers()" />
      </div>
      <ion-card-content class="mainCard">
        <div v-if="pharmacyName" class="ion-text-center">
          <ion-text class="pharmacy">{{ pharmacyName }}</ion-text>
        </div>

        <div class="flex-container space-around distanceGain">
          <ion-item lines="none border">
            <ion-icon class="icon" :icon="navigateOutline"></ion-icon>
            <ion-text>
              <h3>
                {{ distance }}km
              </h3>
            </ion-text>
          </ion-item>
          <ion-item lines="none" class="border">
            <ion-text color="success">
              <h2 v-if="slots?.length > 0">
                {{ calculatedSalary }}€
              </h2>
              <h2 v-else class="mission-flexible">
                Mission flexible
              </h2>
            </ion-text>
          </ion-item>
        </div>

        <div class="flex-container center icons">
          <div class="marginR flex-container center alignItem">
            <ion-icon
              class="ion-item-center icon"
              :icon="calendarOutline">
            </ion-icon>
            <ion-text color="primary">
              <h4>({{ slotsSize || '🚨Mission spéciale🚨' }})</h4>
            </ion-text>
          </div>
        </div>
        <ion-button class="ion-item-center" v-on:click="showMission">
          Voir la mission
        </ion-button>
      </ion-card-content>
    </ion-card>
  </div>
</template>
<script>
import store, {POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES} from "@/store";
import {
  pin,
  medkitOutline,
  documentText,
  logoEuro,
  informationCircleOutline,
  calendarOutline,
  navigateOutline
} from "ionicons/icons";
import {
  IonButton,
  IonIcon,
  IonCard,
  IonItem,
  IonText,
  IonCardContent,
  IonCardHeader,
  IonCardTitle
} from "@ionic/vue";
import Map from "@/components/GoogleMap.vue";
import router from "@/router";
import {baseURL} from "@/services/api";

export default {
  name: "Mission",
  components: {
    Map,
    IonButton,
    IonIcon,
    IonCard,
    IonItem,
    IonText,
    IonCardContent,
    IonCardHeader,
    IonCardTitle
  },
  data() {
    return {
      userLngLat: store.getters.lngLat
    };
  },

  computed: {
    POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES() {
      return POSSIBLE_DISTINCT_SIGNS_SENSIBILITIES
    },
    phoneAlloopharma() {
      return `tel:${store.getters.phoneAlloopharma}`;
    },
    calculatedSalary() {
      if (this.slots === null) {
        return this.salary;
      } else if (this.slots.length === 0) {
        return '∞'
      }
      let salary = 0;
      let increase = 0;
      this.slots.forEach(slot => {
        slot.days.forEach(day => {
          day.slotsDay.forEach(slotDay => {
            if (!slotDay.canTakeIfSuggest) {
              salary += slotDay.salary;
              increase += slotDay.hourWorked * slotDay.increase;
            }
          });
        });
      });
      return Math.floor(salary + increase);
    },
    formatedDate() {
      const { dateStart, dateEnd } = this;
      if (dateStart === 'Invalid date' || dateEnd === 'Invalid date') {
        return 'Reste à définir';
      }
      return `${dateStart} - ${dateEnd}`;
    }
  },
  setup() {
    return {
      pin,
      navigateOutline,
      medkitOutline,
      documentText,
      logoEuro,
      informationCircleOutline,
      calendarOutline
    };
  },
  props: {
    lng: { type: Number, required: false, default: null },
    lat: { type: Number, required: false, default: null },
    distance: { type: Number, required: true },
    address: { type: String, required: true },
    vipType: { type: Boolean, required: true },
    pharmacyName: { type: String, required: false, default: null },
    dateStart: { type: String, required: false, default: null },
    dateEnd: { type: String, required: false, default: null },
    dateTimeMissionConfirmed: { type: Object, required: false, default: null },
    slots: { type: Array, required: false, default: null },
    slotsSize: { type: Number, required: false, default: 0 },
    uniqueUser: { type: Boolean, required: false },
    software: { type: String, required: true },
    salary: { type: Number, required: false, default: null },
    description: { type: String, required: false },
    title: { type: String, required: true },
    triggerFalseSeeSlots: { type: Number, required: false },
    rest: { type: Number, required: false, default: null },
    id: { type: Number, required: false, default: null },
    isPremium: { type: Boolean, required: false, default: false },
    distinctSignsSensibility: { type: String, required: false, default: 'nothing' }
  },
  watch: {
    triggerFalseSeeSlots() {
      this.isSeeSlots = false;
    }
  },

  methods: {
    showMission() {
      store.dispatch('setCurrentMission', { id: this.id });
      router.push("/mission")
    },
    getMarkers() {
      const pharmacyMarker = {
        title: this.pharmacyName,
        position: { lng: this.lng, lat: this.lat },
        icon: baseURL + '/constants/pharmacy'
      };
      return [store.getters.userMarker, pharmacyMarker];
    }
  }
};
</script>
<style scoped>
#container {
  --ion-grid-columns: 24;
}
.dateConfirmed {
  margin-bottom: 14px;
  text-decoration: underline;
}
.flex-container {
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
}

.center {
  justify-content: center;
}
.marginR {
  margin-right: 25px;
}

.border {
  box-shadow: aliceblue;
}
.alignItem {
  display: inline-block;
  vertical-align: middle;
}
.mainCard {
  padding: 8px 8px 0 8px;
}
.marginCard {
  padding: 5px;
  margin: 8px;
  border-radius: 15px;
}
.iconInKnowMore {
  margin-right: 12px;
}
.space-around {
  justify-content: space-around;
}
.icon {
  color: #d56498;
}
.icons {
  margin-bottom: 0;
  background-color: aliceblue;
  background-size: auto;
}
.dateMissionPossible {
  font-size: 18px !important;
}
ion-icon {
  font-size: 30px;
}
ion-row {
  align-items: center;
}
.pharmacy {
  color: var(--ion-color-primary);
  font-size: 21px;
}
h1 {
  color: rgb(109, 109, 109);
  font-size: 18px;
  font-family: "SFProTextBold";
  margin-top: 0;
}
h2 {
  font-size: 25px;
}
h3 {
  color: grey;
  font-size: 20px;
  margin: 5px;
}
h5 {
  margin: 5px;
  color: var(--ion-color-secondary);
}
ion-card-title {
  border-bottom: solid;
  border-color: grey;
}

.headerCard {
  background-color: #d56498;
  padding-top: 4px;
  padding-bottom: 4px;
  margin-bottom: 0 !important;
  color: rgb(255, 247, 247);
}

ion-card-header {
  padding: 0;
  padding-inline: 0;
}

.black {
  color: black;
}

.fullCard {
  padding-bottom: 0;
  border-radius: 35px;

  margin-bottom: 30px;
}
.description {
  font-size: 14px;
  color: var(--ion-color-secondary);
}
.address {
  width: 97%;
}
ion-card-content {
  padding: 0 !important;
}

.mission-flexible {
  font-size: 20px;
  color: var(--ion-color-secondary);
}

.headerCard__gold {
  background-color: #B9A267;

}
</style>
